<template>
    <span class="pl-4">
      <button @click="copy(true)">{{this.buttonText}}</button>
      <span
        v-if="showCopied"
        class="anim-object anim-smallcar success bg-green-500 text-white font-bold py-2 px-4 rounded-full"
      >
        Copied
        <FontAwesomeIcon class="ml-2" icon="check" />
      </span>
    </span>
</template>

<script>
export default {
  props: {
    buttonText: {
      type: String,
      required: true
    },
    token: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showCopied: false
    };
  },
  methods: {
    copy (logic) {
      this.showCopied = logic;
      const copyField = document.createElement('textarea');
      document.body.appendChild(copyField);
      copyField.value = this.token;
      copyField.select();
      document.execCommand('copy');
      document.body.removeChild(copyField);
      this.resetCopied();
    },
    resetCopied () {
      setTimeout(() => {
        this.showCopied = false;
      }, 2000);
    }
  }
};
</script>

<style lang="scss" scoped>
.success {
  position: absolute;
  top: -10px;
  right: 475px;
  opacity: 0;
  animation: opacityOn 3s normal forwards;
}
@keyframes opacityOn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
